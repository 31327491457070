<template>
  <v-card-text class="pa-0">
    <v-card flat height="100%" class="pa-8">
      <v-row no-gutters align="center">
        <v-flex xs12>
          <PageTitle :heading="$t('be_your_self')" :type="'style1'" />
        </v-flex>
        <v-flex xs12>
          <AlertBox :message="videoFileTypeError" class="ma-1 mb-2" />
        </v-flex>
        <v-card flat rounded="lg" color="grey" width="300" height="300">
          <div v-if="profileVideoUrl">
            <video
              controls
              autoplay
              muted
              loop
              height="300"
              width="300"
              class="pa-2 position-relative"
            >
              <source
                :src="profileVideoUrl"
                type="video/mp4"
                height="300"
                width="300"
              />
            </video>
            <v-btn
              class="delete-btn"
              color="white"
              small
              height="30"
              depressed
              @click="
                ;(profileVideoUrl = null),
                  $emit('update:video-file-url', null),
                  (videoStopManual = false)
              "
            >
              <v-icon center>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
          <div v-else class="text-center file-upload">
            <input
              ref="video"
              class="file-upload-input"
              type="file"
              accept="video/*"
              @change="handleFileVideoUpload()"
            />
            <div
              class="text-center drag-text text-subtitle-2 text--opacity-75 upload-button-holder"
            >
              <strong>{{ $t('drag_and_drop') }}</strong>
              <v-btn
                class="mb-5 mt-2 upload"
                color="primary"
                @click="$refs.video.click()"
              >
                <v-icon left>
                  mdi-cloud-upload
                </v-icon>
                {{ $t('upload') }}
              </v-btn>
              <span>{{ $t('image_upload_ten_mb') }}</span>
            </div>
          </div>
        </v-card>

        <v-card
          v-show="!videoStopManual"
          flat
          rounded="lg"
          color="grey"
          width="300"
          height="300"
          class="ml-5"
        >
          <div
            class="text-center drag-text text-subtitle-2 text--opacity-75 recording align-center-box"
          >
            <video id="myVideo" playsinline class="video-js vjs-default-skin">
              <p class="vjs-no-js">
                To view this video please enable JavaScript, or consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                >
                  supports HTML5 video.
                </a>
              </p>
            </video>
            <strong class="mt-10">{{
              !recordingStart ? $t('start_recording') : $t('stop_recording')
            }}</strong>
            <button
              v-if="!recordingStart"
              type="button"
              class="btn-recording start"
              @click.prevent="startRecording()"
            >
              <span>Start</span>
            </button>
            <button
              v-if="recordingStart"
              type="button"
              class="btn-recording stop"
              @click.prevent="stopRecording()"
            >
              <span />
            </button>
          </div>
        </v-card>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
import PageTitle from '@/components/talent-setup/PageTitle.vue'
import { mapGetters } from 'vuex'
//utils
import i18n from '@/utils/i18n'

import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import videojs from 'video.js'
import 'webrtc-adapter'
import RecordRTC from 'recordrtc'
import Record from 'videojs-record/dist/videojs.record.js'
import FFmpegjsEngine from 'videojs-record/dist/plugins/videojs.record.ffmpegjs.js'
import { PROFILE } from '@/graphql/talent'

export default {
  components: {
    PageTitle,
  },
  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileVideoUrl: null,
      profileVideo: null,
      videoFileTypeError: null,
      recordingStart: false,
      videoStopManual: false,
      player: '',
      retake: 0,
      isSaveDisabled: true,
      isStartRecording: false,
      isRetakeDisabled: true,
      submitText: 'Submit',
      options: {
        controls: true,
        bigPlayButton: false,
        controlBar: {
          deviceButton: false,
          recordToggle: false,
          pipToggle: false,
        },
        width: 500,
        height: 300,
        fluid: true,
        plugins: {
          record: {
            pip: false,
            audio: true,
            video: true,
            maxLength: 60,
            videoMimeType: 'video/mp4',
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        console.log('val', val)
        if (val.videoFileUrl) {
          this.profileVideoUrl = val.videoFileUrl
        }
      },
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  mounted() {
    this.player = videojs('myVideo', this.options, () => {
      // print version information at startup
      var msg =
        'Using video.js ' +
        videojs.VERSION +
        ' with videojs-record ' +
        videojs.getPluginVersion('record') +
        ' and recordrtc ' +
        RecordRTC.version
      videojs.log(msg)
    })
    // error handling
    this.player.on('deviceReady', () => {
      this.player.record().start()
      console.log('device ready:')
    })
    this.player.on('deviceError', () => {
      console.log('device error:', this.player.deviceErrorCode)
    })
    this.player.on('error', (element, error) => {
      console.error(error)
    })
    // user clicked the record button and started recording
    this.player.on('startRecord', () => {
      console.log('started recording!')
    })
    // user completed recording and stream is available
    this.player.on('finishRecord', () => {
      if (!this.videoStopManual) {
        this.stopRecording()
      }

      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      this.profileVideoUrl = null
      this.$emit('update:video-file-url', null)
      var blob = new Blob([this.player.recordedData], { type: 'video/mp4' })
      var reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.profileVideoUrl = reader.result
        this.$emit('update:video-file-url', this.profileVideoUrl)
        this.$emit('update:video-base', this.profileVideoUrl.split(',')[1])
        this.$emit('update:video-upload-type', 'base64')
      }
    })
  },
  methods: {
    async getProfile() {
      this.loading = true
      const {
        data: { getBookingPageInfo },
      } = await this.$apollo.query({
        query: PROFILE,
        variables: {
          username: this.user.username,
        },
      })
      if (getBookingPageInfo.success) {
        this.loading = false
        this.profileVideoUrl = getBookingPageInfo.profile.introVideo
      }
    },
    startRecording() {
      this.isStartRecording = true
      this.player.record().getDevice()
      this.toggleRecordingStart()
      this.videoStopManual = false
    },
    stopRecording() {
      this.videoStopManual = true
      this.player.record().stopDevice()
      this.toggleRecordingStart()
    },
    toggleRecordingStart() {
      this.recordingStart = !this.recordingStart
    },
    handleFileVideoUpload() {
      this.videoFileTypeError = null
      /*
        Set the local file variable to what the user has selected.
      */
      this.profileVideo = this.$refs.video.files[0]
      this.$refs.video.value = null

      /*
        Check to see if the file is not empty.
        Ensure the file is an image file.
      */
      if (
        this.profileVideo &&
        /\.(mp4|webm|mkv|mov|wmv)$/i.test(this.profileVideo.name)
      ) {
        if (this.profileVideo.size > 50 * 1024 * 1024) {
          this.showError('video_size_msg')
          return
        }
        /*
          Initialize a File Reader object
        */
        const reader = new FileReader()

        /*
          Add an event listener to the reader that when the file
          has been loaded, we flag the show preview as true and set the
          image to be what was read from the reader.
        */
        reader.addEventListener(
          'load',
          () => {
            this.showRemoveAvatarButton = true
            this.profileVideoUrl = reader.result
            this.$emit('update:video-file-url', this.profileVideoUrl)
            this.$emit('update:video-upload-type', 'object')
          },
          false
        )

        /*
        Fire the readAsDataURL method which will read the file in and
        upon completion fire a 'load' event which we will listen to and
        display the image in the preview.
        */
        reader.readAsDataURL(this.profileVideo)
        this.$emit('update:video-file', this.profileVideo)
      } else {
        this.showError('invalid_video_file_type')
      }
    },

    showError(message) {
      this.videoFileTypeError = i18n.t(message)
      this.$refs.video.value = ''
      this.$nextTick(() => {
        this.timeOut = setTimeout(() => {
          this.videoFileTypeError = null
        }, 3000)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.recording::v-deep .vue-video-recorder {
  background: $white;
}
.btn-recording span {
  background: $dark_red;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 0px;
}
.btn-recording.stop span {
  border-radius: 0%;
}
.btn-recording {
  background: $white;
  width: 50px;
  height: 50px;
}
</style>
<style scoped>
.file-upload {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  height: 300px;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.delete-btn {
  position: absolute;
  right: 10px;
  bottom: 56px;
}
.drag-text {
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.recording {
  margin-top: 0;
  top: 0;
  justify-content: flex-end;
  padding-bottom: 30px;
}

.upload-button-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  top: 0;
  justify-content: center;
}
.upload-button-holder strong {
  display: block;
  margin-bottom: 10px;
}
.upload-button-holder button.upload {
  min-width: 170px;
}
</style>
