<template>
  <div v-if="type=='style1'">
    <p
      v-if="heading"
      class="text-h5 mb-5 text-warp font-weight-bold"
    >
      {{ heading }}
    </p>
    <p
      v-if="subheading"
      class="text-subtitle-2 text-warp text--opacity-50 mb-5"
    >
      {{ subheading }}
    </p>
  </div>
  <v-card
    v-else
    color="light_grey"
    flat
    :class="headingClass"
  >
    <p
      class="text-h3 mb-3 text-warp pt-sm-12 mt-md-0"
    >
      {{ heading }}
    </p>

    <p
      class="text-h4 mb-0 text--opacity-75 text-warp"
      v-html="subheading"
    />
  </v-card>
</template>

<script>
export default {
  props: { 
    heading: { 
      type: String, 
      default: () => '',
    },
    subheading: { 
      type: String, 
      default: () => '',
    },
    type: { 
      type: String, 
      default: () => '', 
    },
    headingClass:{
      type: String, 
      default: () => 'mb-5 pb-5', 
    },
  },
};
</script>
